// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-carpintaria-js": () => import("./../../../src/pages/carpintaria.js" /* webpackChunkName: "component---src-pages-carpintaria-js" */),
  "component---src-pages-chef-ricardo-costa-js": () => import("./../../../src/pages/chef-ricardo-costa.js" /* webpackChunkName: "component---src-pages-chef-ricardo-costa-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cozinhas-js": () => import("./../../../src/pages/cozinhas.js" /* webpackChunkName: "component---src-pages-cozinhas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orcamento-js": () => import("./../../../src/pages/orcamento.js" /* webpackChunkName: "component---src-pages-orcamento-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-projects-dentalclinic-js": () => import("./../../../src/pages/projects/dentalclinic.js" /* webpackChunkName: "component---src-pages-projects-dentalclinic-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-woodland-village-js": () => import("./../../../src/pages/projects/woodland-village.js" /* webpackChunkName: "component---src-pages-projects-woodland-village-js" */),
  "component---src-pages-showroom-js": () => import("./../../../src/pages/showroom.js" /* webpackChunkName: "component---src-pages-showroom-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-subpage-template-js": () => import("./../../../src/templates/subpageTemplate.js" /* webpackChunkName: "component---src-templates-subpage-template-js" */)
}

