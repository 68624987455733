const initialState = {
    product: {
        open: false,
        id: null
    },
    form: {
        open: false,
        step: 1,
        options: {
            option1: '',
            option2: '',
            option3: '',
            option4: '',
        },
        price: '',
        date: '',
        description: '',
        data: {
            name: '',
            tel: '',
            email: '',
            address: ''
        }
    },
    useImg: "",
    useAlt: "",
    useDescription: "",
    useCaption: ""
}

// PRODUCT IN CAROUSEL
    const SET_PRODUCT = 'SET_PRODUCT'

    export const setProduct = product => ({
        type: SET_PRODUCT, product
    })
// ---

// MULTIPLE STEP FORM - ORCAMENTO
    const SET_FORM = 'SET_FORM'

    export const setForm = form => ({
        type: SET_FORM, form
    })
// ---

// SET IMG LOCAL STORAGE
const SET_IMG = 'SET_IMG'

export const setImg = img => ({
    type: SET_IMG, img
})
// ---

// SET IMG LOCAL STORAGE
const SET_ALT = 'SET_ALT'

export const setAlt = alt => ({
    type: SET_ALT, alt
})
// ---

// SET IMG LOCAL STORAGE
const SET_DESCRIPTION = 'SET_DESCRIPTION'

export const setDescription = description => ({
    type: SET_DESCRIPTION, description
})
// ---

// SET IMG LOCAL STORAGE
const SET_CAPTION = 'SET_CAPTION'

export const setCaption = caption => ({
    type: SET_CAPTION, caption
})
// ---


export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCT:
            return { 
                ...state, 
                product: action.product
            }
        case SET_FORM:
            return {
                ...state,
                form: action.form
            }
        case SET_IMG:
            return {
                ...state,
                img: action.img
            }
        case SET_ALT:
            return {
                ...state,
                alt: action.alt
            }
        case SET_CAPTION:
            return {
                ...state,
                caption: action.caption
            }
        case SET_DESCRIPTION:
            return {
                ...state,
                description: action.description
            }
        default:
            return state
    }
}